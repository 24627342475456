<div class="hidden md:block" [class.no-menu]="displayMenu === false">
  <div>
    <div class="h-2 bg-ace-blue-400 mb-1"></div>
    <div class="section-container px-3 flex items-center justify-end">
      <a
        href="https://driversed.com/support/"
        class="text-sm text-ace-blue-400 hover:text-ace-blue-200 underline mr-5"
        data-test="helpLink"
      >
        Help Center
      </a>
      <button
        class="bg-ace-green-400 hover:bg-ace-green-200 text-xs font-bold text-vis-reverse uppercase py-1 px-3 rounded"
        data-test="loginButton"
      >
        <a href="https://app.driversed.com/user/signin/">Log In</a>
      </button>
    </div>
    <div *ngIf="showLogoHref; else altLogo" class="section-container px-3">
      <a href="/">
        <img
          [class.logo]="displayMenu === false"
          class="inline-block"
          src="https://dec-mkt.imgix.net/images/logo.png?auto=compress,format"
          height="45"
          width="280"
          data-test="decLogo"
        />
        <img
          *ngIf="secondaryLogo"
          class="addl-logo inline-block pl-4"
          [src]="secondaryLogo.url"
          alt="{{ secondaryLogo.alt }}"
        />
      </a>
    </div>
    <ng-template #altLogo>
      <div class="section-container px-3" data-test="brandLogo">
        <img
          [class.logo]="displayMenu === false"
          class="inline-block"
          src="https://dec-mkt.imgix.net/images/logo.png?auto=compress,format"
          height="45"
          width="280"
        />
      </div>
    </ng-template>
    <div *ngIf="displayMenu" class="section-container px-3 relative">
      <ul class="flex justify-center" data-test="headerMenu">
        <li
          class="menu-item flex flex-col items-center justify-center text-center px-0 mx-2 py-3 text-base font-bold text-ace-blue-400 hover:underline cursor-pointer relative italic"
          data-test="stateSelector"
        >
          <div #currentStateToggler (click)="toggleMenu()" (keydown.enter)="onEnter()" tabindex="0">
            <span #stateArrow class="z-10">
              <fa-icon
                [ngClass]="{ hidden: !menuOpen }"
                class="absolute main-menu__dropdown-arrow caret-down"
                [icon]="faCaretDown"
                [styles]="faCaretDownStyles"
              >
              </fa-icon>
              <fa-icon
                [ngClass]="{ hidden: menuOpen }"
                class="absolute main-menu__dropdown-arrow caret-right"
                [icon]="faCaretRight"
                [styles]="faCaretRightStyles"
              >
              </fa-icon>
            </span>
            <span>{{ currentMenu.state }}</span>
          </div>
        </li>
        <div
          *ngIf="stateSelectionList"
          class="z-50 main-menu__dropdown absolute w-full left-0"
          [ngClass]="{ 'main-menu__dropdown_open': menuOpen }"
        >
          <ul #statesDropdown class="flex flex-col flex-wrap px-5 py-5 bg-ace-grey-100" data-test="stateListDropdown">
            <li *ngFor="let item of stateSelectionList; let first = first; let last = last">
              <a
                tabindex="0"
                href="{{ item.href }}"
                class="{{ first ? 'first' : last ? 'last' : '' }}"
                (blur)="onBlur($event)"
                (keydown)="onKeyDown($event)"
              >
                {{ item.text }}
              </a>
            </li>
          </ul>
        </div>
        <ng-template ngFor let-group [ngForOf]="menuGroupList">
          <li
            *ngIf="group[0]"
            class="menu-item flex flex-col items-center justify-center text-center px-0 mx-2 py-3 text-base font-bold"
            data-test="menuItems"
          >
            <a [ngClass]="{ underline: group[0].isCurrentItem }" href="{{ group[0].href }}">{{ group[0].text }}</a>
            <ul *ngIf="group.length > 1" class="flex {{ group.length > 3 && 'flex-wrap justify-center' }}">
              <ng-template ngFor let-subItem let-i="index" [ngForOf]="group.slice(1)">
                <li class="menu-sub-item font-normal text-sm leading-tight" data-test="subMenuItems">
                  <a [ngClass]="{ underline: subItem.isCurrentItem }" href="{{ subItem.href }}">{{ subItem.text }}</a
                  ><span *ngIf="i < group.length - 2" class="px-1">&#8226;</span>
                </li>
              </ng-template>
            </ul>
          </li>
        </ng-template>
      </ul>
    </div>
  </div>
</div>
<dec-mobile-menu class="block md:hidden" [state]="data.state" [menuGroupList]="menuGroupList"></dec-mobile-menu>
