/* eslint-disable prettier/prettier */
import * as gql from 'fake-tag';

export const LayoutFragment = {
  Layout: gql`
    fragment LayoutQuery on baseComponents_layout_BlockType {
      backgroundImage {
        url
        ... on images_Asset {
          altText
        }
      }
      buttonAlignment
      buttonLayout
      buttons {
        buttonLabel
        buttonLink
        buttonLinkNewTab
        buttonLinkNofollow
        buttonType
      }
      dropdown {
        dropdownLabel
        dropdownLink
        dropdownLinkNewTab
        dropdownLinkNofollow
      }
      dropdownButtonType
      dropdownPlaceholder
      dropdownTopLabel
      highlightedTextModal1 {
        ... on highlightedTextModal1_BlockType {
          modalContent
        }
      }
      highlightedTextModal2 {
        ... on highlightedTextModal2_BlockType {
          modalContent
        }
      }
      image {
        url
        ... on images_Asset {
          altText
        }
      }
      imageAlignment
      imageVsContent
      layoutContent
      showTrustpilot
      layoutRightContent
      layoutTitle
      lineBreak_buttons
      lineBreak_dropdown
      lineBreak_mainContent
      lineBreak_modals
      lineBreak_styles
      styles
      typeHandle
    }
  `,
};
