import type { OnInit } from '@angular/core';
import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { Image } from '@core-mkt/interfaces/image';
import { faCaretDown, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import type { MenuItem } from '../../header/header';
import { Menu } from '../../header/header';
import { MenuGroup, newHeaderData } from '../../header/header-data';
import { stateList } from '../../header/state-nav-data';

@Component({
  selector: 'dec-desktop-menu',
  templateUrl: './desktop-menu.component.html',
  styleUrls: ['./desktop-menu.component.scss'],
})
export class DesktopMenuComponent implements OnInit {
  @Input() data: Menu;
  @Input() secondaryLogo: Image;
  @Input() _showLogoHref = true;
  _menuGroups: MenuItem[][];
  @ViewChild('currentStateToggler') currentStateToggler;
  @ViewChild('statesDropdown') statesDropdown;
  @ViewChild('stateArrow') stateArrow;
  faCaretRight = faCaretRight;
  faCaretRightStyles = { width: '6px', height: '24px' };
  faCaretDown = faCaretDown;
  faCaretDownStyles = { width: '10px', height: '24px' };
  currentMenu: Menu;
  menuOpen = false;
  tabShift: boolean;

  get menuGroupList(): MenuItem[][] {
    return this._menuGroups;
  }

  get stateSelectionList(): MenuItem[] {
    return stateList;
  }

  get showLogoHref(): boolean {
    return this._showLogoHref;
  }

  get isGlobalPage(): boolean {
    return this.data.state === 'Select State';
  }

  get displayMenu(): boolean {
    if (this.menuGroupList === undefined) {
      return false;
    }
    const menuGroupListLength = this.menuGroupList.length;
    return menuGroupListLength === 0 ? false : true;
  }

  constructor() {}

  ngOnInit() {
    this.currentMenu = this.data;
    if (this.data.state === 'Select State') {
      this.formatMenuGroups();
    } else {
      this.setMenuForState();
    }
  }

  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  getLinkForGroup(groupEntry: MenuItem): string {
    switch (groupEntry.text) {
      case MenuGroup.DriversEd:
      case MenuGroup.Teens:
      case MenuGroup.Adults:
      case MenuGroup.PracticePermitTest:
        return this.isGlobalPage ? groupEntry.href : `/${this.data.state.toLowerCase()}${groupEntry.href}`;
      case MenuGroup.DMVInformation:
        return this.isGlobalPage ? groupEntry.href : `${groupEntry.href}${this.data.state.toLowerCase()}`;
      default:
        return groupEntry.href;
    }
  }

  setMenuForState(): void {
    this.currentMenu.state = this.data.state;
    const currentState: string = this.currentMenu.state.toLowerCase();
    const stateMenu = newHeaderData[currentState];
    const removeTrafficSchool = ['alabama', 'arizona', 'kentucky', 'maine', 'michigan', 'texas'];
    const removeDefensiveDriving = ['arizona'];
    if (stateMenu && stateMenu.dmvLabel) {
      this.currentMenu.dmvLabel = stateMenu.dmvLabel;
    }
    if (stateMenu && stateMenu.reWriteDefault) {
      this.currentMenu.menuItems = stateMenu.menuItems;
    } else {
      for (const menuItem of this.currentMenu.menuItems) {
        menuItem.href = this.getLinkForGroup(menuItem);
        if (menuItem.text === 'DMV Information' && stateMenu && stateMenu.dmvLabel) {
          menuItem.text = stateMenu.dmvLabel;
        }
        // Remove 'Traffic School' item for states in the removeTrafficSchool array
        if (menuItem.text === 'Traffic School' && removeTrafficSchool.includes(currentState)) {
          this.currentMenu.menuItems = this.currentMenu.menuItems.filter(
            (item) => !item.text.includes('Traffic School'),
          );
        }

        //if the menu item has the text 'In-Car Driving Lessons' currentMenu.showInPersonLessons
        //is false, remove the menu item from the menu list.
        if (menuItem.text === 'In-Car Driving Lessons' && !this.currentMenu.showInPersonLessons) {
          this.currentMenu.menuItems = this.currentMenu.menuItems.filter(
            (item) => item.text !== 'In-Car Driving Lessons',
          );
        }
      }
      //if there are menu items for the state add them to the menuList based on the order field of the menu item in the stateMenu menuItems array.
      if (stateMenu && stateMenu.menuItems.length > 0) {
        for (const menuItem of stateMenu.menuItems) {
          const matchingMenuItemIdx = this.currentMenu.menuItems.findIndex((item) => item.text === menuItem.text);
          //skip to next iteration of the for loop if page has turned off show in person lessons and the menu item is 'In-Car Driving Lessons'.
          if (menuItem.text === 'In-Car Driving Lessons' && !this.currentMenu.showInPersonLessons) {
            continue;
          }
          if (matchingMenuItemIdx !== -1) {
            this.currentMenu.menuItems[matchingMenuItemIdx].href = menuItem.href;
          } else {
            this.currentMenu.menuItems.splice(menuItem.order, 0, menuItem);
          }

          // Remove 'Defensive Driving' item for states in the removeDefensiveDriving array
          if (menuItem.text === 'Defensive Driving' && removeDefensiveDriving.includes(currentState)) {
            this.currentMenu.menuItems = this.currentMenu.menuItems.filter(
              (item) => !item.text.includes('Defensive Driving'),
            );
          }
        }
      }
    }
    this.formatMenuGroups();
  }

  onEnter(): void {
    this.toggleMenu();
  }

  @HostListener('document:click', ['$event'])
  click(event) {
    if (this.displayMenu) {
      if (
        event.target !== this.statesDropdown.nativeElement &&
        !this.statesDropdown.nativeElement.contains(event.target)
      ) {
        if (
          event.target !== this.currentStateToggler.nativeElement &&
          !this.currentStateToggler.nativeElement.contains(event.target)
        ) {
          if (event.target !== this.stateArrow.nativeElement && !this.stateArrow.nativeElement.contains(event.target)) {
            this.menuOpen = false;
          }
        }
      }
    }
  }

  /**
    * Hide dropdown menus after the last item is unfocused/blurred and tabbed forward.
    * If the last item is tabbed backwards, the menu stays open.
    * If the first item is tabbed backwards, the menu closes.
    @param event The blur event that triggered the function.
    */
  onBlur(event): void {
    const isFirstMenuItem = event.target.classList.contains('first');
    const isLastMenuItem = event.target.classList.contains('last');

    // Update the visibility of the menus based on the tabbing direction and item position.
    if ((isLastMenuItem && !this.tabShift) || (isFirstMenuItem && this.tabShift)) {
      this.menuOpen = false;
    }
    if ((isLastMenuItem && !this.tabShift) || (isFirstMenuItem && this.tabShift)) {
      this.menuOpen = false;
    }
  }

  /**
   * Checks if user is tabbing backwards
   * @param event The keydown KeyboardEvent that triggers the function
   */
  onKeyDown(event: KeyboardEvent): void {
    this.tabShift = event.shiftKey && event.key === 'Tab' ? true : false;
  }

  formatMenuGroups(): void {
    const menuGroupList: MenuItem[][] = [];
    let groupIndex = -1;
    // Build the list of menu groups by pushing to the same index until a new position of "top" is seen;
    // until "top" is seen, the menu item is assumed to be a sub-item ("bottom") so is pushed to the same
    // group. Once a new "top" is seen, a new group gets formed at the following index and any sub-items
    // will be pushed there, etc., until the whole array has been iterated over.
    for (let i = 0; i < this.currentMenu.menuItems.length; i++) {
      if (this.currentMenu.menuItems[i].position === 'top') {
        groupIndex++;
        menuGroupList[groupIndex] = [];
      }
      menuGroupList[groupIndex].push(this.currentMenu.menuItems[i]);
    }
    this._menuGroups = menuGroupList;
  }
}
