<div class="md:hidden">
  <div class="bg-ace-blue-800 z-30 py-0 text-vis-reverse fixed w-full">
    <div class="flex items-center justify-between px-4 py-3">
      <div class="w-1/5">
        <div [class.open]="!hidden" class="fixed-sidebar-toggle" (click)="mobileMenuToggle()">
          <a class="navicon-button cursor-pointer inline-block relative pr-1">
            <span class="navicon rounded-lg inline-block relative text-vis-reverse w-4"></span>
          </a>
          <span class="uppercase text-xs cursor-pointer" tabindex="0" (keydown.enter)="onEnter()">Menu</span>
        </div>
      </div>
      <div *ngIf="showLogoHref; else altLogo" class="w-3/5 flex justify-center">
        <a class="inline-block" href="/" title="Drivers Ed Home">
          <img
            class="logo"
            src="https://dec-mkt.imgix.net/images/driversed_logo_blue_white.png?auto=compress,format&w=139&fit=max"
            alt="DriversEd.com"
            height="13"
            width="139"
          />
        </a>
      </div>
      <ng-template #altLogo>
        <div class="w-3/5 flex justify-center">
          <img
            class="logo"
            src="https://dec-mkt.imgix.net/images/driversed_logo_blue_white.png?auto=compress,format&w=139&fit=max"
            alt="DriversEd.com"
            height="13"
            width="139px"
          />
        </div>
      </ng-template>
      <div class="w-1/5"></div>
    </div>
  </div>

  <div class="overlay z-30" [ngClass]="hidden ? 'hidden' : 'block'" (click)="mobileMenuToggle()"></div>

  <div
    class="z-40 submenu bg-dec-blue-600 fixed h-full mt-12"
    [class.is-active]="!hidden"
    [ngClass]="hidden ? 'hidden' : 'block'"
  >
    <div class="p-4 text-vis-reverse cursor-pointer text-center border-t-8 border-ace-blue-550 bg-white">
      <a
        href="https://app.driversed.com/user/signin/"
        class="bg-ace-green-400 hover:bg-olive-ace-200 px-3 py-1 rounded uppercase text-xs font-semibold"
      >
        Log In
      </a>
    </div>
    <div class="text-center bg-dec-blue-800">
      <div class="text-center text-vis-reverse">
        <ul class="bg-ace-blue-600">
          <li *ngIf="displayMenu">
            <span
              class="font-bold underline py-2 cursor-pointer block"
              (click)="mobileStatesToggle()"
              (keydown.enter)="mobileStatesToggle()"
              tabindex="0"
            >
              <fa-icon
                [ngClass]="{ hidden: !statesHidden }"
                class="z-30 text-ace-blue-400 absolute main-menu__dropdown-arrow caret-down"
                [icon]="faCaretDown"
                [styles]="faCaretDownStyles"
              >
              </fa-icon>
              <fa-icon
                [ngClass]="{ hidden: statesHidden }"
                class="z-30 text-ace-blue-400 absolute main-menu__dropdown-arrow caret-right"
                [icon]="faCaretRight"
                [styles]="faCaretRightStyles"
              >
              </fa-icon>
              <i>{{ state }}</i>
            </span>
          </li>
          <li
            *ngIf="stateSelectionList && displayMenu"
            style="max-height: 260px"
            class="overflow-auto bg-dec-blue-800"
            [class.hidden]="!statesHidden"
          >
            <ul>
              <li *ngFor="let item of stateSelectionList">
                <a href="{{ item.href }}" class="cursor-pointer py-1 block"> {{ item.text }}</a>
              </li>
            </ul>
          </li>
          <ng-template ngFor let-group [ngForOf]="menuGroupList">
            <li *ngIf="group[0] && displayMenu">
              <a
                [ngClass]="{ underline: group[0].isCurrentItem }"
                class="cursor-pointer font-semibold py-2 block top-border bottom-border"
                href="{{ group[0].href }}"
                >{{ group[0].text }}</a
              >
              <ul *ngIf="group.length > 1" class="flex-col">
                <ng-template ngFor let-subItem let-i="index" [ngForOf]="group.slice(1)">
                  <li>
                    <a
                      [ngClass]="{ underline: subItem.isCurrentItem }"
                      class="bg-dec-blue-800 py-2 block"
                      href="{{ subItem.href }}"
                      >{{ subItem.text }}</a
                    >
                  </li>
                </ng-template>
              </ul>
            </li>
          </ng-template>
          <li>
            <a
              href="https://driversed.com/support/"
              class="cursor-pointer font-semibold py-2 block top-border bottom-border"
            >
              Help Center
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
