import type { MenuItem, NavData } from './header';

export const headerData: MenuItem[] = [
  {
    text: 'Drivers Ed',
    href: '/drivers-ed/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Teens',
    href: '/teen-drivers-ed/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Adults',
    href: '/adult-drivers-ed/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'In-Car Driving Lessons',
    href: 'https://driversed.com/driving-lessons/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Traffic School',
    href: 'https://driversed.com/traffic-school/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Practice Permit Test',
    href: '/practice-permit-test/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'DMV Information',
    href: '/dmv/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Blog',
    href: '/trending/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Community',
    href: '/community/',
    position: 'top',
    isCurrentItem: false,
  },
  {
    text: 'Apps',
    href: '/apps/drivers-ed/',
    position: 'bottom',
    isCurrentItem: false,
  },
  {
    text: 'Partners',
    href: '/affiliates/',
    position: 'bottom',
    isCurrentItem: false,
  },
];

export const newHeaderData: NavData = {
  default: { menuItems: headerData, state: 'Select State', dmvLabel: 'DMV Information' },
  alabama: {
    state: 'Alabama',
    dmvLabel: 'DPS Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: 'https://www.idrivesafely.com/defensive-driving/alabama/',
        text: 'Defensive Driving School',
        position: 'top',
        order: 3,
      },
    ],
  },
  arizona: {
    state: 'Arizona',
    dmvLabel: 'MVD Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: '/arizona/traffic-school/',
        text: 'Defensive Driving',
        position: 'top',
        order: 3,
      },
    ],
  },
  arkansas: { state: 'Arkansas', dmvLabel: 'ASP Information', menuItems: [] },
  california: {
    state: 'California',
    menuItems: [
      {
        isCurrentItem: false,
        href: 'https://driversed.com/california/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
      {
        isCurrentItem: false,
        href: 'https://driversed.com/california/traffic-school/',
        text: 'Traffic School',
        position: 'top',
        order: 4,
      },
    ],
  },
  colorado: {
    state: 'Colorado',
    menuItems: [
      {
        isCurrentItem: false,
        href: '/colorado/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
    ],
  },
  florida: {
    state: 'Florida',
    reWriteDefault: true,
    menuItems: [
      {
        isCurrentItem: false,
        href: '/florida/learners-permit/',
        text: "Learner's Permit",
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: '/florida/combo/',
        text: '15-17',
        position: 'bottom',
      },
      {
        isCurrentItem: false,
        href: '/florida/drug-and-alcohol/',
        text: '18+',
        position: 'bottom',
      },
      {
        isCurrentItem: false,
        href: '/florida/permit-test/',
        text: "Learner's Permit Test",
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: '/florida/practice-permit-test/',
        text: 'Practice Permit Test',
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: '/dmv/florida/',
        text: 'Driver & Vehicle Services',
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: 'https://driversed.com/trending/',
        text: 'Blog',
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: '/community/',
        text: 'Community',
        position: 'top',
      },
      {
        isCurrentItem: false,
        href: '/apps/drivers-ed/',
        text: 'Apps',
        position: 'bottom',
      },
      {
        isCurrentItem: false,
        href: '/affiliates/',
        text: 'Partners',
        position: 'bottom',
      },
    ],
  },
  georgia: {
    state: 'Georgia',
    dmvLabel: 'DDS Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: 'https://driversed.com/georgia/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
    ],
  },
  hawaii: { state: 'Hawaii', dmvLabel: 'DOT Information', menuItems: [] },
  illinois: { state: 'Illinois', dmvLabel: 'SOS Information', menuItems: [] },
  indiana: {
    state: 'Indiana',
    dmvLabel: 'BMV Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: '/indiana/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
    ],
  },
  iowa: { state: 'Iowa', dmvLabel: 'DOT Information', menuItems: [] },
  kentucky: { state: 'Kentucky', dmvLabel: 'DDL Information', menuItems: [] },
  maine: { state: 'Maine', dmvLabel: 'BMV Information', menuItems: [] },
  massachusetts: { state: 'Massachusetts', dmvLabel: 'RMV Information', menuItems: [] },
  michigan: {
    state: 'Michigan',
    dmvLabel: 'SOS Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: 'https://driversed.com/michigan/traffic-school/',
        text: 'BDIC',
        position: 'top',
        order: 3,
      },
    ],
  },
  missouri: { state: 'Missouri', dmvLabel: 'DOR Information', menuItems: [] },
  montana: { state: 'Montana', dmvLabel: 'MVD Information', menuItems: [] },
  nevada: {
    state: 'Nevada',
    dmvLabel: 'MVC Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: '/nevada/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
    ],
  },
  ohio: {
    state: 'Ohio',
    dmvLabel: 'BMV Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: 'https://driversed.com/ohio/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
    ],
  },
  oklahoma: { state: 'Oklahoma', dmvLabel: 'SOK Information', menuItems: [] },
  'south-dakota': { state: 'South Dakota', dmvLabel: 'DPS Information', menuItems: [] },
  tennessee: { state: 'Tennessee', dmvLabel: 'TDOS Information', menuItems: [] },
  texas: {
    state: 'Texas',
    dmvLabel: 'DPS Information',
    menuItems: [
      {
        isCurrentItem: false,
        href: '/texas/driving-lessons/',
        text: 'In-Car Driving Lessons',
        position: 'top',
        order: 3,
      },
      {
        isCurrentItem: false,
        href: '/texas/defensive-driving/',
        text: 'Defensive Driving',
        position: 'top',
        order: 4,
      },
      {
        isCurrentItem: false,
        href: '/texas/driving-record/',
        text: 'Driving Record',
        position: 'top',
        order: 5,
      },
    ],
  },
  utah: { state: 'Utah', dmvLabel: 'DPS Information', menuItems: [] },
  washington: { state: 'Washington', dmvLabel: 'DOL Information', menuItems: [] },
  wyoming: { state: 'Wyoming', dmvLabel: 'DOT Information', menuItems: [] },
};

export enum MenuGroup {
  DriversEd = 'Drivers Ed',
  Teens = 'Teens',
  Adults = 'Adults',
  PracticePermitTest = 'Practice Permit Test',
  DMVInformation = 'DMV Information',
}
