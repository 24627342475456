/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { DOCUMENT } from '@angular/common';
import type { AfterViewInit, OnInit } from '@angular/core';
import { Component, Inject, Input, ViewChild } from '@angular/core';
import type { SafeHtml } from '@angular/platform-browser';
import type { Image } from '@core-mkt/interfaces/image';
import { ImgixService } from '@core-mkt/services/imgix/imgix.service';
import type { ComponentTheme, ThemeInterface } from '@core-mkt/services/theme-parser/theme-parser.service';
import { ThemeParserService } from '@core-mkt/services/theme-parser/theme-parser.service';
import { WysiwygRedactorService } from '@core-mkt/services/wysiwg-redactor/wysiwyg-redactor.service';
import type { VisionButton } from '@core-mkt/shared/components/vision-button/vision-button/vision-button';
import type { DropdownData } from '@core-mkt/shared/components/vision-dropdown/vision-dropdown/vision-dropdown';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import type { DropdownLayoutItems, ProductPicker } from './layout';
import { Layout } from './layout';

import { BrandConfigurationService } from '@core-mkt/services/configuration/brand-configuration.service';
import { EnvService } from '@core-mkt/services/env/env.service';
import { TrustpilotService } from '@core-mkt/services/trustpilot/trustpilot.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent implements OnInit, AfterViewInit {
  @Input() data: Layout;
  @ViewChild('layout') layout;
  theme: ThemeInterface;
  faChevronDown = faChevronDown;
  useLazyLoad = false;
  faTimes = faTimes;
  buttonsData: VisionButton[] = [];
  buttonWidth: string;
  tertiaryLayout: string;
  dropdownAlignment: string;
  modalContent: string;
  showModal = false;
  trustpilotTrustScore: number;
  trustpilotNumberOfReviews: number;
  trustpilotVerified = false;
  truspilotBrandUrl: string;

  constructor(
    private redactor: WysiwygRedactorService,
    private themeParser: ThemeParserService,
    private imgixService: ImgixService,
    private tps: TrustpilotService,
    private bcs: BrandConfigurationService,
    private env: EnvService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.truspilotBrandUrl = `https://www.trustpilot.com/review/${this.bcs.url}`;
  }

  get image(): Image {
    if (this.data.image && !!this.data.image[0]) {
      return this.data.image[0];
    }
    return null;
  }

  get imageUrl(): string {
    if (this.data.image && !!this.data.image[0]) {
      if (this.data.image[0].url.includes('auto=compress,format&w=534')) {
        return this.data.image[0].url;
      }
      return `${this.data.image[0].url}&auto=compress,format&w=534`;
    }
    return null;
  }

  get contentCardTitle(): string | undefined {
    return this.data.contentCardTitle;
  }

  get layoutTitle(): SafeHtml {
    return this.data.layoutTitle;
  }

  get layoutContent(): SafeHtml {
    return this.data.layoutContent;
  }

  get layoutRightContent(): SafeHtml {
    return this.data.layoutRightContent;
  }

  get dropdownTopLabel(): string {
    return this.data.dropdownTopLabel;
  }

  get dropdownPlaceholder(): string {
    return this.data.dropdownPlaceholder;
  }

  get dropdownButtonType(): string {
    return this.data.dropdownButtonType;
  }

  get dropdown(): DropdownLayoutItems[] {
    return this.data.dropdown;
  }

  get dropdownData(): DropdownData {
    return {
      dropdownItems: this.dropdown,
      dropdownTopLabel: this.dropdownTopLabel,
      dropdownPlaceholder: this.dropdownPlaceholder,
      dropdownAlignment: this.dropdownAlignment,
    };
  }

  get buttons(): VisionButton[] {
    return this.buttonsData;
  }

  get buttonLayout(): string {
    if (this.buttons.length > 3 || (this.buttons.length > 2 && this.dropdown.length > 0)) {
      return 'flex flex-col xs:flex-row flex-wrap justify-center';
    }
    return this.data.buttonLayout.includes('flex-row')
      ? this.data.buttonLayout.replace('flex-row', 'flex-col xs:flex-row')
      : this.data.buttonLayout;
  }

  get buttonAlignment(): string {
    return this.buttons.length > 3 ? '' : this.data.buttonAlignment;
  }

  get hasButtonRow(): boolean {
    return this.data.buttonLayout !== null && this.data.buttonLayout === 'grid grid-cols-2';
  }

  get isCard(): boolean {
    return !!this.data.cardType;
  }

  get isCardContent(): boolean {
    return this.data.cardType === 'card';
  }

  get cardImageStyle(): string {
    if (this.data.cardImage && !!this.data.cardImage[0]) {
      return `url(${this.data.cardImage[0].url})`;
    }
    return '';
  }

  get cardContent(): SafeHtml {
    return this.data.cardContent;
  }

  get cardButtonType(): string {
    return this.data.cardButtonType;
  }

  get cardButtonLabel(): string {
    return this.data.cardButtonLabel;
  }

  /**
   * Gets the content of the product picker.
   * @returns The first element of the productPicker array from Craft
   */
  get productPickerContent(): ProductPicker[] {
    if (
      this.data.productPickerTable &&
      this.data.productPickerTable.length > 0 &&
      this.data.productPickerTable[0].productPickerTable
    ) {
      return this.data.productPickerTable;
    }
  }

  get cardButtonData(): VisionButton {
    return {
      bgTheme: this.data.backgroundColor,
      buttonLink: this.data.cardButtonLink,
      buttonText: this.data.cardButtonLabel,
      buttonStyle: this.data.cardButtonType,
      newTab: this.data.cardButtonLinkNewTab,
      nofollow: this.data.cardButtonLinkNofollow,
      noopener: this.data.cardButtonLinkNewTab,
      noreferrer: this.data.cardButtonLinkNewTab,
      fullWidth: false,
      useIcon: true,
      ctaTag: this.data.cardButtonCtaTag,
    };
  }

  get flexClass(): string {
    if (this.image || this.layoutRightContent) {
      /* TODO: Remove hack after V2
       * There are several imageAligment fields in the single template. Standardize them to use the same Labels/Values
       */
      if (this.data.imageAlignment.startsWith('flex-col')) {
        //V2 Version
        return this.data.imageAlignment.replace('md:', 'lg:');
      } else {
        //V3 Version, need this for a layout fix
        return `flex-col-reverse lg:${this.data.imageAlignment}`;
      }
    }
    if (this.isCard) {
      return this.data.cardAlignment;
    }
    return '';
  }

  ngOnInit(): void {
    if (this.data.index > 2) {
      this.useLazyLoad = true;
    }
    this.dropdownAlignment = this.data.layoutContent?.toString().match(/text-align:\s*right/)
      ? 'float-right'
      : this.data.layoutContent?.toString().match(/text-align:\s*center/)
      ? undefined
      : 'float-left';
    this.data.contentCardTitle = this.redactor.bypassSanitizer(this.data.contentCardTitle ?? null);
    this.data.layoutTitle = this.redactor.bypassSanitizer(this.data.layoutTitle);
    this.data.layoutContent = this.redactor.bypassSanitizer(this.data.layoutContent, 'lazy');
    this.data.layoutRightContent = this.redactor.bypassSanitizer(this.data.layoutRightContent, 'lazy');
    this.data.cardContent = this.redactor.bypassSanitizer(this.data.cardContent, 'lazy');
    const paddingWidth = 5.5;
    const maxValue = Math.max(...this.buttonsData.map((b) => b.buttonText.length));
    if (this.buttonsData.length === 2) {
      const minValue = Math.min(...this.buttonsData.map((b) => b.buttonText.length));
      const difference = maxValue - minValue;
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      this.buttonWidth = (difference < paddingWidth ? maxValue + difference - 1.5 : maxValue - 1) + paddingWidth + 'ch';
    } else if (this.buttonsData.length > 2) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      this.buttonWidth = maxValue + paddingWidth + 'ch';
    }
    const themeInput: ComponentTheme = {
      styles: this.data.styles,
      backgroundColor: this.data.backgroundColor,
      backgroundImage: this.data.backgroundImage?.[0],
      textColor: this.data.textColor,
      columnCount: 0,
      columnDistribution: '',
    };
    this.theme = this.themeParser.getThemeClasses(themeInput);
    const buttons = this.data.buttons ? this.data.buttons : this.data.aarpButtons;
    buttons?.forEach((button) => {
      const buttonData: VisionButton = {
        bgTheme: this.theme.root,
        buttonLink: button.buttonLink,
        buttonText: button.buttonLabel,
        buttonStyle: button.buttonType,
        newTab: button.buttonLinkNewTab,
        nofollow: button.buttonLinkNofollow,
        noopener: button.buttonLinkNewTab,
        noreferrer: button.buttonLinkNewTab,
        fullWidth: false,
        ctaTag: button.buttonCtaTag,
      };

      this.buttonsData.push(buttonData);
    });

    if (this.data.showTrustpilot && this.env.get.brandConfig.trustpilotId.length > 0) {
      void this.tps.getBrandStarsRating().then((trustPilotRating) => {
        this.trustpilotTrustScore = trustPilotRating.score.trustScore;
        this.trustpilotNumberOfReviews = trustPilotRating.numberOfReviews.total;
        this.checkTrustpilotStars();
      });
    }
  }

  ngAfterViewInit(): void {
    if (
      this.layout.nativeElement.getElementsByClassName('highlighted-text-modal-1').length > 0 &&
      this.data.highlightedTextModal1.length > 0
    ) {
      this.layout.nativeElement.getElementsByClassName('highlighted-text-modal-1')[0].addEventListener('click', () => {
        this.modalContent = this.redactor.bypassSanitizer(this.data.highlightedTextModal1[0].modalContent);
        this.showModal = true;
      });
    }

    if (
      this.layout.nativeElement.getElementsByClassName('highlighted-text-modal-2').length > 0 &&
      this.data.highlightedTextModal2.length > 0
    ) {
      this.layout.nativeElement.getElementsByClassName('highlighted-text-modal-2')[0].addEventListener('click', () => {
        this.modalContent = this.redactor.bypassSanitizer(this.data.highlightedTextModal2[0].modalContent);
        this.showModal = true;
      });
    }
  }

  getButtonClasses(isLast: boolean): string {
    if (!this.buttonLayout.includes('grid') && !this.buttonLayout.includes('flex-row')) {
      return isLast ? '' : 'block mb-3';
    }
    return isLast ? 'block mb-3 mt-1.5' : 'block mb-3 mt-1.5 mr-2';
  }

  private checkTrustpilotStars(): void {
    if (this.trustpilotNumberOfReviews) {
      this.trustpilotVerified = true;
    }
  }

  openTruspilot(): void {
    window.open(this.truspilotBrandUrl, '_blank');
  }
}
