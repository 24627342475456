import { brandConfig } from '@core-mkt/brands/ins/config';
import type { Env } from '@core-mkt/services/env/env';

export const environment: Env = {
  production: true,
  envMode: 'dev',
  craftApiUrl: 'https://craft-v3.ecom-dev.aceable.com/api/',
  xgritCheckoutUrl: 'https://checkout.ecom-dev.driversed.com/checkout',
  xgritApiConfig: {
    url: 'https://stg.xgritapi.com/api/1/',
    aceauth: 'Basic MDY5MGI2MDc4ZjBmNTUwOTJlMmFiZDNlMmY3ODk3Zjg6',
    baseParams: {
      appType: 'RE',
      platform: 'MKT',
      brandId: 'INS',
    },
  },
  defaultOgImage: '',
  optimizelyId: '',
  optimizelyHandle: '',
  brandConfig,
  sentryDsn: '',
  segmentId: '',
  segmentCdnProxy: '',
  segmentApiProxy: '',
  feefoMarchantId: '',
  twitterHandle: '',
  facebookDomainVerification: '',
  googleSiteVerification: '',
  wistiaVerification: '',
  ipifyUrl: '',
  xccApiUrl: '',
  gladlyHelpCenterEnvironment: {
    api: '',
    orgId: '',
    brandId: '',
    cdn: '',
    selector: '',
  },
};
