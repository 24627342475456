export const iconConfig = [
  {
    type: 'svg',
    name: 'accordion-close',
    icon: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path _ngcontent-serverApp-c36="" d="M8.12998 15.7901L12.01 11.9101L15.89 15.7901C16.28 16.1801 16.91 16.1801 17.3 15.7901C17.69 15.4001 17.69 14.7701 17.3 14.3801L12.71 9.79006C12.32 9.40006 11.69 9.40006 11.3 9.79006L6.70998 14.3801C6.31998 14.7701 6.31998 15.4001 6.70998 15.7901C7.09998 16.1701 7.73998 16.1801 8.12998 15.7901Z" fill="#F19E1C">
  </path>
</svg>`,
  },
  {
    type: 'svg',
    name: 'accordion-open',
    icon: `<svg _ngcontent-serverApp-c36="" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path _ngcontent-serverApp-c36="" d="M15.88 9.79006L12 13.6701L8.11998 9.79006C7.72998 9.40006 7.09998 9.40006 6.70998 9.79006C6.31998 10.1801 6.31998 10.8101 6.70998 11.2001L11.3 15.7901C11.69 16.1801 12.32 16.1801 12.71 15.7901L17.3 11.2001C17.69 10.8101 17.69 10.1801 17.3 9.79006C16.91 9.41006 16.27 9.40006 15.88 9.79006Z" fill="#F19E1C"></path></svg>`,
  },
  {
    type: 'svg',
    name: 'slider-left',
    icon: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="48" height="48" rx="24" fill="#F19E1C"/>
  <path d="M24.375 31.4C24.6083 31.1833 24.725 30.9125 24.725 30.5875C24.725 30.2625 24.6083 29.9833 24.375 29.75L19.8 25.175H30.65C30.9833 25.175 31.2625 25.0625 31.4875 24.8375C31.7125 24.6125 31.825 24.3333 31.825 24C31.825 23.6666 31.7125 23.3875 31.4875 23.1625C31.2625 22.9375 30.9833 22.825 30.65 22.825H19.8L24.375 18.225C24.6083 18.0083 24.725 17.7333 24.725 17.4C24.725 17.0666 24.6083 16.7833 24.375 16.55C24.1417 16.3333 23.8625 16.225 23.5375 16.225C23.2125 16.225 22.9333 16.3333 22.7 16.55L16.075 23.175C15.9583 23.2916 15.8708 23.4208 15.8125 23.5625C15.7542 23.7041 15.725 23.85 15.725 24C15.725 24.15 15.7542 24.2958 15.8125 24.4375C15.8708 24.5791 15.9583 24.7083 16.075 24.825L22.7 31.45C22.9333 31.6833 23.2125 31.7916 23.5375 31.775C23.8625 31.7583 24.1417 31.6333 24.375 31.4Z" fill="white"/>
  </svg>`,
  },
  {
    type: 'svg',
    name: 'slider-right',
    icon: `<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect width="48" height="48" rx="24" fill="#F19E1C"/>
  <path d="M23.175 31.4C22.9417 31.1833 22.825 30.9125 22.825 30.5875C22.825 30.2625 22.9417 29.9833 23.175 29.75L27.75 25.175H16.9C16.5667 25.175 16.2875 25.0625 16.0625 24.8375C15.8375 24.6125 15.725 24.3333 15.725 24C15.725 23.6666 15.8375 23.3875 16.0625 23.1625C16.2875 22.9375 16.5667 22.825 16.9 22.825H27.75L23.175 18.225C22.9417 18.0083 22.825 17.7333 22.825 17.4C22.825 17.0666 22.9417 16.7833 23.175 16.55C23.4083 16.3333 23.6875 16.225 24.0125 16.225C24.3375 16.225 24.6167 16.3333 24.85 16.55L31.475 23.175C31.5917 23.2916 31.6792 23.4208 31.7375 23.5625C31.7958 23.7041 31.825 23.85 31.825 24C31.825 24.15 31.7958 24.2958 31.7375 24.4375C31.6792 24.5791 31.5917 24.7083 31.475 24.825L24.85 31.45C24.6167 31.6833 24.3375 31.7916 24.0125 31.775C23.6875 31.7583 23.4083 31.6333 23.175 31.4Z" fill="white"/>
  </svg>`,
  },
  { type: 'font-awesome', name: 'resourcesLinkIcon', icon: 'faArrowRight' },
];
